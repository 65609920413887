//  const baseUrl='https://goalalert.gameit.in/';
// const baseUrl = "http://localhost:5030/";
const baseUrl='/';
export { baseUrl };

const liveMatchesApi = "api/live-matches";
export { liveMatchesApi };

const liveMatchesAllApi = "api/live-matches-all";
export { liveMatchesAllApi };

const currentDayMatchesApi = "api/current-matches";
export { currentDayMatchesApi };

const recentMatchesApi = "api/recent-matches";
export { recentMatchesApi };

const weeklyMatchesApi = "api/weekly-matches";
export { weeklyMatchesApi };

const allMatchesApi = "api/all/matches";
export { allMatchesApi };

const matchByIdApi = "api/match/";
export { matchByIdApi };

const matchPredictionApi = "api/match-prediction/";
export { matchPredictionApi };

const teamLineupsApi = "api/team-lineups";
export { teamLineupsApi };

const matchesByDateApi = "api/matches-date";
export { matchesByDateApi };

const matchStatisticsApi = "api/team-statistics/";
export { matchStatisticsApi };

const playerStatisticsApi = "api/player-statistics/";
export { playerStatisticsApi };

const playersApi = `api/players/`;
export { playersApi };

const signupApi = `api/signup`;
export { signupApi };

const loginApi = `api/login`;
export { loginApi };

const logoutApi = `api/logout`;
export { logoutApi };

const standingsApi = "api/standings";
export { standingsApi };

const playerTopScoresApi = `api/player-top-scores`;
export { playerTopScoresApi };

const playerTopAssistsApi = `api/player-top-assists`;
export { playerTopAssistsApi };

const adminGetMatchesApi = `api/admin/get-matches`;
export { adminGetMatchesApi };

const fetchPlayersApi = `api/get-team-players`;
export { fetchPlayersApi };

const createUserTeamApi = `api/create-user-team`;
export { createUserTeamApi };

const getUserMatchesApi = `api/get-user-matches`;
export { getUserMatchesApi };

const getUserTeamApi = `api/get-user-team`;
export { getUserTeamApi };

const getMatchLeaderboardApi = `api/get-match-leaderboard`;
export { getMatchLeaderboardApi };

const userRewardsApi = `api/user-rewards`;
export { userRewardsApi };

const fetchMatchContestsApi = `api/match/contests`;
export { fetchMatchContestsApi };

const createUserContestApi = `api/create-user-contest`;
export { createUserContestApi };

const fetchMatchContestByIdApi = `api/get-match-contest`;
export { fetchMatchContestByIdApi };

const checkOtherPlayersTeamApi = `api/check-other-player-teams`;
export { checkOtherPlayersTeamApi };

const checkPlayerStatsApi = `api/check-player-points`;
export { checkPlayerStatsApi };

const userNotificationsApi = `api/user/notifications`;
export { userNotificationsApi };

const userNotificationsCheckApi = `api/user/notifications/check`;
export { userNotificationsCheckApi };

const deleteNotificationApi = `api/user/notification/delete`;
export { deleteNotificationApi };

// Test Api's

const getMatchesByDateTest = `api/get-matches-by-date-test`;
export { getMatchesByDateTest };


// qwkjan