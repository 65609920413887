import React, { useEffect, useState } from "react";
import classes from "../css/UserMatchesPage.module.css";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import MenuTabs from "../components/MenuTabs";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { baseUrl, getUserMatchesApi } from "../api/api";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ContestLayout from "../components/ContestLayout";
import MatchGridLayout from "../components/Match-Container/MatchGridLayout";
import HeadingWithGrid from "../components/Headings/HeadingWithGrid";
import MessageBoxSimple from "../components/Message-Box/MessageBoxSimple";
import { Paginator } from "primereact/paginator";

const UserMatchesPage = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.userSlice);
  const { lang } = useSelector((state) => state.langSlice);
  const navigate = useNavigate();
  const location = useLocation();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);
  const [totalRecords, setTotalRecords] = useState(null);

  const fetchUserMatches = async (loader) => {
    try {
      if (loader?.loading == true) {
        setLoading(true);
      }
      const response = await axios.post(
        `${baseUrl}${getUserMatchesApi}`,
        {
          userId: user?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setMatches(response?.data?.message);
      setTotalRecords(response?.data?.message?.length);
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.token) {
      navigate("/login", {
        state: {
          status: "redirect",
          path: "/user/matches",
          message: "Login or Sign Up",
        },
      });
      return;
    }
    fetchUserMatches({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchUserMatches({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const onPageChange = (event) => {
    setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    },200)
    window.scrollTo({
      top: 0,
      behavior:"instant"
    });
    navigate(`?page=${event?.page+1}`);
  };


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    if (!page) {
      setFirst(0);
      setRows(6);
    } else {
      setFirst(6 * (page - 1));
    }
  }, [location]);

  return (
    <Layout active={4} hideMenu={true}>
      <Drawer active={4} />
      <div className={classes.container}>
        <MenuTabs />
        {loading ? (
          <Loader />
        ) : (
          <MatchGridLayout>
            <HeadingWithGrid
              title={
                lang == "english"
                  ? "My Matches"
                  : lang == "spanish"
                  ? "Mis partidos"
                  : lang == "french"
                  ? "Mes matchs"
                  : lang == "arabic"
                  ? "مبارياتي"
                  : "My Matches"
              }
            />
            {matches?.length > 0 ? (
              <>
                {matches?.slice(first, first + rows).map((match, index) => {
                  return <ContestLayout match={match} key={index} />;
                })}
                <div className={classes.pagination}>
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                  />
                </div>
              </>
            ) : (
              <MessageBoxSimple
                title={
                  lang == "english"
                    ? "You have not joined any matches...."
                    : lang == "spanish"
                    ? "No te has unido a ninguna partida...."
                    : lang == "french"
                    ? "Vous n'avez rejoint aucun match...."
                    : lang == "arabic"
                    ? "لم تنضم إلى أي مباراة...."
                    : "You have not joined any matches...."
                }
              />
            )}
          </MatchGridLayout>
        )}
      </div>
    </Layout>
  );
};

export default UserMatchesPage;
