import { BreadCrumb } from "primereact/breadcrumb";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Menu.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl, logoutApi } from "../api/api";
import { logoutUser } from "../slices/userSlice";
import { toast } from "react-toastify";

const Menu = ({ activeIndex }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state?.userSlice);
  const { lang } = useSelector((state) => state?.langSlice);

  const logoutHandler = async () => {
    try {
      const res = await axios.get(`${baseUrl}${logoutApi}`);
      console.log(res, "res");
      dispatch(logoutUser());
      toast.success("Logout Successfully!");
      navigate("/");
    } catch (error) {
      dispatch(logoutUser());
    }
  };

  const items = [
    {
      label:
        lang == "english"
          ? "Recent"
          : lang == "spanish"
          ? "Reciente"
          : lang == "french"
          ? "Récent"
          : lang == "arabic"
          ? "مؤخرًا"
          : "Recent",
      command: () => {
        navigate("/recent/matches");
      },
    },
    {
      label:
        lang == "english"
          ? "Upcoming"
          : lang == "spanish"
          ? "Próximo"
          : lang == "french"
          ? "A venir"
          : lang == "arabic"
          ? "القادمة"
          : "Upcoming",
      command: () => {
        navigate("/upcoming/matches");
      },
    },
    {
      label:
        lang == "english"
          ? "Fantasy Game"
          : lang == "spanish"
          ? "Juego de fantasía"
          : lang == "french"
          ? "Jeu fantastique"
          : lang == "arabic"
          ? "لعبة الخيال"
          : "Fantasy Game",
      command: () => {
        navigate("/fantasy/game");
      },
    },
    {
      label:
        lang == "english"
          ? "Standings"
          : lang == "spanish"
          ? "Clasificación"
          : lang == "french"
          ? "Classement"
          : lang == "arabic"
          ? "الترتيب"
          : "Standings",
      command: () => {
        navigate("/team/standings");
      },
    },
    user && user.token
      ? {
          label:
            lang == "english"
              ? "Logout"
              : lang == "spanish"
              ? "Cerrar sesión"
              : lang == "french"
              ? "Se déconnecter"
              : lang == "arabic"
              ? "تسجيل خروج"
              : "Logout",
          command: () => {
            logoutHandler();
          },
        }
      : null,
    !(user && user.token)
      ? {
          label:
            lang == "english"
              ? "Login"
              : lang == "spanish"
              ? "Acceso"
              : lang == "french"
              ? "Se connecter"
              : lang == "arabic"
              ? "تسجيل الدخول"
              : "Login",
          command: () => {
            navigate("/login");
          },
        }
      : null,
    !(user && user.token)
      ? {
          label:
            lang == "english"
              ? "Sign Up"
              : lang == "spanish"
              ? "Inscribirse"
              : lang == "french"
              ? "S'inscrire"
              : lang == "arabic"
              ? "اشتراك"
              : "Sign Up",
          command: () => {
            navigate("/signup");
          },
        }
      : null,
  ].filter((item) => item !== null);

  const home = {
    // label: "Home",
    icon: "fa-solid fa-house",
    url: "/",
    command: () => {
      navigate("/");
    },
  };

  return (
    <div className={classes.menu_container}>
      <BreadCrumb model={items} home={home} className={classes.menu} />
    </div>
  );
};

export default Menu;
