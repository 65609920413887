import { BreadCrumb } from "primereact/breadcrumb";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MenuTabs.module.css";
import { useSelector } from "react-redux";

const MenuTabs = ({ activeIndex }) => {
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.langSlice);
  const { user } = useSelector((state) => state?.userSlice);
  const items = [
    {
      label:
        lang == "english"
          ? "Game"
          : lang == "spanish"
          ? "Juego"
          : lang == "french"
          ? "Jeu"
          : lang == "arabic"
          ? "لعبة"
          : "Game",
      url: "/fantasy/game",
      command: () => {
        navigate("/fantasy/game");
      },
    },
    {
      label:
        lang == "english"
          ? "My Matches"
          : lang == "spanish"
          ? "Mis partidos"
          : lang == "french"
          ? "Mes matchs"
          : lang == "arabic"
          ? "مبارياتي"
          : "My Matches",
      command: () => {
        navigate("/user/matches");
      },
    },
    {
      label:
        lang == "english"
          ? "Points"
          : lang == "spanish"
          ? "Puntos"
          : lang == "french"
          ? "Points"
          : lang == "arabic"
          ? "نقاط"
          : "Points",
      command: () => {
        navigate("/user/points");
      },
    },
    // {
    //   label: "Wallet",
    //   url: "/user/wallet",
    //   command: () => {
    //     navigate("/user/wallet");
    //   },
    // },
  ].filter((item) => item !== null);
  const home = {
    // label: "Home",
    icon: "fa-solid fa-house",
    url: "/",
    command: () => {
      navigate("/");
    },
  };

  return (
    <div className={classes.menu_container}>
      <BreadCrumb model={items} home={home} className={classes.menu} />
    </div>
  );
};

export default MenuTabs;
